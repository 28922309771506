body {
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Coolvetica';
    src: url('assets/fonts/Coolvetica.ttf') format('truetype');
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('assets/fonts/Inter-Regular.ttf') format('truetype');
    font-style: normal;
}
